

.review_container {
  padding: 32px 64px 128px 64px;
  opacity: 0;

  margin-top: 4rem;
}


.review_title {
  color: var(--color-text-primary);
  font-size: 35px !important;
  font-weight: bold;
  padding-bottom: 1em;
  text-align: left;
}

.review_text {
  color: var(--color-text-primary);
  font-size: 18px !important;
  text-align: justify;
  padding-bottom: 0.5em;
  line-height: 1.2;
  hyphens: auto;
}

.review_curation {
  color: var(--color-text-primary);
  font-size: 0.9em;
  padding-bottom: 0.5em;
  font-weight: bold;
  text-align: justify;
  line-height: 1.2;
  hyphens: auto;
}


@media  (min-width:600px) {
    .review_container {
      padding: 64px 96px;
    }
    .review_title {
      font-size: 40px;
      padding-bottom: 47px;
    }
    .review_text {
      font-size: 18px !important;
      padding-bottom: 0.5em;
    }
    .review_curation {
      font-size: 16px;
      padding-bottom: 0.5em;
    }
}