.auth_container {
  padding: 0 2em 0 2em;
}

.auth_title1 {
  font-display: block;
  font-family: "Project Bold";
  font-size: 1.3em;
  color: #fddc7e;
  text-align: left;
}

.auth_title2 {
  font-display: block;
  font-family: "Project Bold";
  font-size: 1.3em;
  line-height: 25px;
  color: var(--color-text-primary);
  text-align: left;
}

@media (min-width: 600px) {
  .auth_title1 {
    font-size: 1em;
  }

  .auth_title2 {
    font-size: 1em;
    line-height: 25px;
  }
}
