.fog_background {
  position: fixed;
  z-index: 8000;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
}

.modal_container {
  display: flex;
  flex-direction: column;

  height: 90vw;
  width: 90vw;
  max-height: 400px;
  max-width: 400px;

  bottom: 20px;

  z-index: 8000;

  background-image: url(../images/bg/bg_full_login.png);
  background-position: center;
  background-size: cover;

  padding: 16px 32px;

  border-radius: 8px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.6);

  color: var(--color-text-primary);
}

.modal_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  position: relative;
}

.modal_content .title {
  text-align: left;
  font-size: 24px;
  margin-top: -2rem;
  font-family: 'Project Bold';
}

.modal_content .subtitle {
  text-align: right;
  font-size: 18px;
  font-family: 'Intro Regular';
  margin-top: -1rem;
}

.modal_content .bell_icon {
  width: 120px;
  height: 120px;

  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 720px) {
  .modal_container {
    padding: 8px;
  }
}

.modal_background {
  width: 100%;
}

.modal_footer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-between;

  height: 18%;

  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: -1rem;
  margin-left: auto;
}

.btn {
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 2rem;

  height: 45%;

  border: none;

  background: transparent;
  background-position: center;
  background-size: cover;

  cursor: pointer;
  transform: skewX(-45deg);
  color: var(--color-main);
  font-size: 16px;
}

.btn div {
  transform: skewX(45deg);
	font-family: 'Project Bold';
}

.accept {
  background: var(--color-button-background-primary);
}

.dismiss {
  border: 1px solid var(--color-button-background-primary);
  color: var(--color-button-background-primary);
  margin-right: 4rem;
}
