.pwa-page {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1002;

	background: rgba(0, 0, 0, 0.6);
}

.pwa-container {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;

	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
}

.pwa-install-modal button {
	cursor: pointer;
}

.pwa-close-button {
	display: flex;
	align-self: flex-start;
	background: transparent;
	border: 0;
	font-size: 2rem;

	margin-right: -0.5rem;
	margin-top: -0.5rem;
}

.pwa-close-button svg {
	color: var(--color-primary);
}

.pwa-content p {
	font-family: "Inter Regular";
	font-size: 1rem;
	margin: 0;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: space-between;
}

.pwa-texts {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.pwa-content-title {
	font-family: "Project ExtraBold"!important;
	color: var(--color-title-primary)!important;

	text-align: left;
}

.pwa-content-subtitle {
	font-family: "Intro Regular"!important;
	color: var(--color-title-primary)!important;
	
	text-align: left;
}

.pwa-content img {
	width: 4rem;
	border-radius: 0.5rem;
	margin: 0px 0px;
}

/* Style for IOS modal */
.pwa-page-ios-container {
	max-width: 50rem;
	width: 96%;
	position: fixed;
	left: 2%;
	bottom: 1rem;

	padding-bottom: 8px;
	border-radius: 1.4rem;
	background: #f2f2f2;
}

@media(min-width: 700px) {
	.pwa-page-ios-container {
		top: 1rem;
		right: 1rem;
		left: auto;
		bottom: auto;
	}
}

.arrow-down::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 1px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #f2f2f2;
  transform: translate(-55%, 100%);
}

@media(min-width: 700px) {
	.arrow-down::before {
		content: '';
		position: absolute;
		display: block;
		width: 0px;
		top: -3.7rem;
		right: 5.8rem;
		left: auto;
		bottom: auto;
		border: 15px solid transparent;
		border-bottom: 15px solid #f2f2f2;
	}
}

.pwa-page-ios-header {
	padding: 0.6rem 1.5rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.image-logo {
	width: 2.8rem;
	height: 2.8rem;

	border-radius: 0.4rem;
}

.pwa-page-ios-header-title {
	font-size: 0.9rem;
	font-weight: bold;
	color: var(--color-black);

	display: flex;
	flex-direction: row;
	align-items: center;

	margin-left: 0.8rem;
	margin-right: auto;
}

.pwa-page-ios-header-title svg {
	width: 1.2rem;
	height: 1.2rem;

	padding: 0.8rem;

	border-radius: 0.5rem;

	background-color: var(--color-white)!important;

	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);

	margin-right: 0.6rem;
}

.pwa-page-ios-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	padding: 0.6rem 1.5rem;
}

.pwa-page-ios-content-title {
	font-size: 0.8rem;
	text-align: left;
	color: var(--color-black);

	margin-top: 1rem;
	margin-bottom: 1.5rem;
}


.text-hightliter {
	display: flex;
	flex-direction: row;
	align-items: center;

	font-size: 0.7rem;
	font-weight: bold;

	padding: 0.5rem 0.4rem 0.4rem 0.4rem;
	margin-left: 0.2rem;
	border-radius: 0.3rem;
	color: #000;

	background-color: var(--color-white)!important;
}


.steps {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	
	font-size: 0.8rem;
	color: var(--color-black);

	

}

@media screen and (min-width: 450px) {
	.steps {
		margin-left: -1.3rem;
	}
}

.steps-1 svg {
	width: 1.5rem;
	height: 1.5rem;

	padding: 0.2rem 0.2rem 0.4rem 0.2rem;

	margin-left: 0.5rem;

	border-radius: 0.2rem;

	background-color: var(--color-white)!important;
}

.steps-2 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.steps-2 svg {
	width: 1.5rem;
	height: 1.5rem;

	margin-left: 0.5rem;
}

.steps + .steps {
	margin-top: 0.5rem;
}

.pwa-page-ios-container-button {
	width: 2.5rem;
	height: 2.5rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	border-radius: 1.5rem;
	border: 0;

	background-color: var(--color-white)!important;

	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.10);

	position: absolute;
	top: 0.4rem;
	right: 0.4rem;
}

.pwa-page-ios-container-button svg {
	width: 2.3rem;
	height: 2.3rem;

	color: var(--color-black);
}

.pwa-install-modal {
	width: 100%;
	min-height: 6rem;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../images/bg2-mdc-shortcut-pwa.png");
	background-size: cover;
	justify-content: flex-end;
}
.pwa-install-modal .pwa-content {
	display: flex;
	flex-direction: column;
	padding:.8rem .4rem 1rem 1rem;
}

.pwa-install-modal .pwa-install-button {
	margin-left: auto;

	border: 0;

	margin-right: 1rem;
	margin-bottom: 0px;
	transform: skewX(-45deg);
	background: var(--color-button-background-primary);
	color: var(--color-main);
}

.pwa-install-modal .pwa-install-button div {
	transform: skewX(45deg);
	font-family: 'Project Bold';
}

@media(min-width:390px){
	.pwa-install-modal .pwa-install-button {
		padding: .5rem 2.5rem 0.6rem 2.5rem;
		font-size: 1rem;
	}
}

.pwa-install-modal .pwa-close-button {
	padding:6px;
}
.pwa-install-modal .pwa-close-button svg {
	color: var(--color-title-primary);
}