

.carrossel_container {}

.carrossel_slide_container {
    width: 100%;
    padding: 1em 0 1.25em 0;
    display: flex;
    justify-content: center;
    /* background-color: blueviolet; */
}

.carrossel_slide_container .slider .slider-frame {
    overflow: visible!important;
}

.carrossel_wrap {
    opacity: 0;
}

.carrossel_slide {
    width: 206.5px;
    height: 329.7px;
    margin-top: 15px;
    opacity: 1;
    display: inline-flex;
    /* background-color: var(--color-secondary); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.slide-visible .carrossel_slide {
    width: 206.5px;
    height: 329.7px;
    margin-top: 0px;
    opacity: 1;
    box-shadow: 8px 8px 0px 0px rgba(0,0,0,0.2);
    -webkit-transition-property: width height margin opacity box-shadow;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height margin opacity box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.book_mount{
    width: 206.5px;
    height: 329.7px;
}