.disclaimer_container {
  padding: 1.5em;  
  opacity: 0;
}

.disclaimer_title {
  color: var(--color-text-primary);
  font-size: 14px;
  text-align: center;
  padding: 20px;
  hyphens: auto;
}
