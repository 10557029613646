



.book_detail_container {
    opacity: 0;
}

.book_detail_container button {

    cursor: pointer;
    font-family: "Project Bold";
    font-size: 30px;
    color: var(--color-main);
    background-color: var(--color-button-background-primary);
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-button-background-primary);
    padding: 5px 20px 5px 20px;
    margin: 0.1em 8px 1em 8px;
    
}

.book_detail_container :disabled {
    opacity: 0.5;
}


@media  (min-width:600px) {
    .book_detail_container button {
        font-size: 19px;
        margin: 5px 8px 10px 8px;
    }
}

.book_detail_link {
    font-family: "Intro Regular";
    font-size: 18px;
    color: var(--color-text-primary);
}