.other-books-section {
  width: 100%;
  height: 100%;
  
  background-image: url('./../images/bg/bg-pattern-secondary.png'), url('../images/bg/bg-other-books-center.png');
  background-position: top;
  background-size: 800px auto, 650px auto;
  background-repeat: repeat-y;
  
  padding-top: 2rem;
  padding-bottom: 2rem; 
}

.other_books_top {
  height: 6rem;

  background-image: url('../images/bg/bg-other-books-top.png');
  background-position: top;
  background-size: 500px 6rem;
  background-repeat: no-repeat;
}

.other_books_bottom {
  height: 6rem;

  background-image: url('../images/bg/bg-other-books-bottom.png');
  background-position: bottom;
  background-size: 500px 6rem;
}
