@font-face {
  font-family: 'Project Bold';
  font-style: normal;
  font-weight: bold;
  src: url('./../fonts/Podkova-Bold.ttf');
}
@font-face {
  font-family: 'Project ExtraBold';
  font-style: normal;
  src: url('./../fonts/Podkova-ExtraBold.ttf');
}
@font-face {
  font-family: 'Intro Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./../fonts/Podkova-Regular.ttf');
}

:root {
  --number-body-responsive-width: 600px;
  --number-body-default-width: 300px;
  --number-body-modal-width: 300px;
  --number-body-default-width-half: 150px;
  --number-body-default-height: 300px;
  --number-body-default-height-half: 150px;
  --number-carrossel-default-height: 254px;
  --number-carrossel-default-height-half: 127px;

  --color-primary: #f89f41;
  --color-secondary: #6d6e71;
  --color-complementary-one: #7f491e;
  --color-complementary-two: #1786b7;
  --color-complementary-tree: #ff00ff;
  --color-complementary-four: #00ff00;

  --color-button-primary: #ffea00;
  --color-button-secondary: #e7d3b1;

  --color-title-primary: #42252a;
  --color-title-secondary: #869050;
  --color-title-tertiary: #c39e4e;
  --color-text-primary: #c39e4e;
  --color-text-secondary: #3f2128;

  --color-disclaimer-primary: #c39e4e;

  --color-footer-primary: #4c4c4c;
  --color-slider-buttons-primary: #1273a7;
  --color-alert-primary: yellowgreen;
  --color-yellow: yellow;
  --color-white: white;
  --color-black: black;
  --color-placeholder: #6d6e71;
  --color-modal-background: #edeef8;
  --color-modal-text: #535656;

  --color-background-main: #e7d3b1;
  --color-background-404: #005b86;

  --color-main: #e9dfcf;

  --color-text-primary: #6b6b6b;
  --color-text-light: #f2f2ea;
  --color-text-secondary: #9c8888;
  --color-button-background-primary: #6b6b6b;

}

body {
  margin: 0;
  font-family: 'Intro Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-image: url('./../images/bg/Pattern-MDC.png');
  background-size: auto;
  background-repeat: repeat-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide {
  display: none;
}

button {
  outline: none;
}

ul {
  outline: none;
}

li {
  outline: none;
}

div {
  outline: none;
}

.modal {
  background-color: var(--color-modal-background);
  color: var(--color-modal-text);
  text-align: left;
  margin-left: 2em;
  margin-right: 2em;
  max-height: 80vh;
  max-width: var(--number-body-modal-width);
  border-radius: 8px;
  margin-bottom: 2em;
  overflow: hidden;
}

.modal-callus {
  text-align: center;
}

.modal-container {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  width: var(--number-body-modal-width);
  background-color: var(--color-modal-background);
  margin: 10px auto;
}

.modal h2 {
  font-size: 1.5em;
  padding: 1em;
}

.modal h3 {
  padding-right: 1em;
  padding-left: 1em;
}

.modal p {
  font-size: 1em;
  line-height: 1.4em;
  padding: 1em;
}

.modal button {
  font-family: 'Project Bold';
  cursor: pointer;
  font-size: 12px;
  color: var(--color-modal-text);
  background-color: #ffffff00;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 2em;
  padding-right: 2em;
  border-color: var(--color-modal-text);
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  margin: 5px;
}

.modal-regulamento {
  background-color: var(--color-modal-background);
  color: var(--color-modal-text);
  text-align: left;
  margin-left: 2em;
  margin-right: 2em;
  max-height: 80vh;
  max-width: min(80vw, 450px);
  border-radius: 8px;
  margin-bottom: 2em;
  overflow: hidden;
  padding: 26px 26px 0px 26px;
}

.modal-regulamento * {
  color: var(--color-modal-text);
}

.modal-regulamento-container {
  min-height: 50px;
  max-height: 70vh;
  overflow: scroll;
}

.modal-button-regulamento-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  background-color: var(--color-modal-background);
  margin-bottom: 10px;
}

.modal-regulamento button {
  font-family: 'Project Bold';
  cursor: pointer;
  font-size: 12px;
  color: var(--color-modal-text);
  background-color: #ffffff00;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 2em;
  padding-right: 2em;
  border-color: var(--color-modal-text);
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  margin: 5px;
}
.modal-container-callus {
  min-height: 50px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
