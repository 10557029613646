.App {
  width: 100vw;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;

  background-image: url('./../images/bg/bg_full_login.png'), url('./../images/bg/bg-pattern-secondary.png'), url('./../images/bg/bg-base.png');
  background-size: 500px auto;
  background-position: top;
  background-repeat: no-repeat, repeat-y, repeat-y;
}

.login {
  background-image: url('./../images/bg/bg_full_login.png'); 
  background-size: auto;
  background-position: top center; 
  background-repeat: no-repeat;
}

.inicializing {
  height: 100vh;
}

.error404 {
  
}

@media (min-width: 600px) {
  .App{
    width: var(--number-body-responsive-width);
    background-size: 700px 3850px;
    box-shadow:0px 0px 15px 10px rgba(0,0,0,0.1);
    -webkit-transition-property: opacity box-shadow;
    transition-property: opacity box-shadow;
  }
}

@media (min-width: 700px) {
  .App{
    background-size: 700px 3850px;
  }
}

@media (min-width: 900px) {
  .App{
    background-size: 700px auto;
  }
}

#section1 {
  width: 100%;
  height: 100%;
}

#section2 {
  width: 100%;
  height: 100%;
}

#section3 {
  width: 100%;
  height: 100%;
}

#section4 {
  width: 100%;
  height: 100%;
}
