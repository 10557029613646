.wrapper {
	padding-top: 20px;
	display: flex;
	justify-content: center;
}

.switch {
	position: relative;
	width: 60px;
	height: 20px;
	background-color: #b4ca68;
	border-radius: 13px;
	cursor: pointer;
	align-self: center;
}
.ball {
	position: absolute;
	top: 3px;
	left: 5px;
	width: 15px;
	height: 15px;
	border-radius: 13px;
	background-color: var(--color-title-primary);
}

span {
	font-size: 18px;
	padding: 0 20px;
	color: #e7d3b2;
}

.doubts {
	width: 30px;
	height: 30px;
	background-image: url('./../../assets/images/icon_ask.png');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	margin-left: 80%;
}
