.main_logo {
    padding-top: 3px;
    width: 314px;
}

.main_logo_landingpage {
    width: 240px;
    margin-top: 24px;
    margin-left: -130px;
}

@media (min-width: 600px) {
    .main_logo_landingpage {
        width: 240px;
        margin-top: 24px;
        margin-left: -190px;
    }
}
