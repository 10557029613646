.auth_login_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  width: 100%;
}

.auth_login_container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.auth_login_container button {
  font-family: "Project Bold";
  cursor: pointer;
  font-size: 18px;
  color: var(--color-main);
  background-color: var(--color-button-background-primary);
  margin: 1.4em 4px 4px 4px;
  border-width: 0px;
  width: 60%;
  max-width: 250px;
  height: 40px;
  transform: skewX(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.auth_login_message {
  padding-top: 1em;
  text-align: right;
  color: #e7d3b2;
  width: 227px;
  font-size: 1em;
}

.auth_rhombus {
  background-color: #b4ca68;
  width: 155px;
  height: 40px;
  transform: skewX(-45deg);
  display: flex;
  justify-content: center;
}

.auth_call_us {
  font-family: "Project Bold";
  background-color: unset;
  border-width: 0px;
  color: var(--color-text-primary);
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
}

.inputWrapper {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth_login_container input {
  font-family: unset;
  text-align: center;
  font-size: 0.8em;
  background-color: #e7d3b2;
  width: 19em;
  margin-top: 4px;
  margin-bottom: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  padding-left: 20px;
  border-width: 0px;
  box-sizing: border-box;
}

.auth_login_error {
  font-size: 13px;
  color: var(--color-text-primary);
  margin-top: -15px;
  align-self: flex-start;
}

.auth_login_container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-title-primary);
  opacity: 1; /* Firefox */
}

.auth_login_container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-title-primary);
}

.auth_login_container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-title-primary);
}

@media (min-width: 600px) {
  .auth_call_us {
    font-size: 0.8em;
  }
  .auth_login_message {
    font-size: 0.8em;
  }
}

.checkbox-wrapper {
  display: flex;
  align-self: flex-start;
}

.checkbox-wrapper p {
  font-size: initial;
  padding-left: 20px;
  color: #e7d3b2;
}

.checkbox {
  width: 7px;
  height: 7px;
  border: solid 4px white;
  border-radius: 20px;
  align-self: center;
  cursor: pointer;
}
.auth_method_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  max-width: 480px;
  width: 100%;
  overflow-y: scroll;
  padding: 10px 0 0 0;
  height: 300px;
  scroll-snap-type: y mandatory;
}

.auth_method_list::-webkit-scrollbar {
  width: 20px; /* width of the entire scrollbar */
}

.auth_method_list::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list::-webkit-scrollbar-thumb {
  background-color: #6b6b6b; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.auth_method_list_loading {
  height: 250px;
}

.auth-method-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 480px;
  padding: 0 1em 0 1em;
  box-sizing: border-box;
}

.auth_method_title {
  font-family: "Project Bold";
  font-size: 1.3em;
  line-height: 25px;
  color: var(--color-text-primary);
  text-align: left;
  align-self: flex-start;
}

@media (min-width: 600px) {
  .auth-method-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 480px;
    padding: 0;
  }

  .auth_method_title {
    font-family: "Project Bold";
    font-size: 1em;
    line-height: 25px;
    color: var(--color-text-primary);
    text-align: left;
    align-self: flex-start;
  }
}

.auth_method_item {
  display: flex;
  padding: 0 14px;
  width: 80%;
  min-width: 270px;
  margin-bottom: 15px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 10px;
  background-color: var(--color-white);
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  cursor: pointer;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.auth_method_item_infos {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.auth_method_item_infos span {
  color: var(--color-secondary);
  font-size: 12px;
  font-family: "Project Bold";
  font-weight: 300;
  text-transform: uppercase;
  padding: 0;
}

.auth_method_item_infos img {
  height: 50px;
  margin-right: 10px;
}

.WelcomeButton {
  font-family: "Project Bold";
  cursor: pointer;
  font-size: 18px;
  color: var(--color-main);
  background-color: var(--color-button-background-primary);
  margin: 1.4em 4px 4px 4px;
  border-width: 0px;
  width: 60%;
  max-width: 250px;
  height: 40px;
  transform: skewX(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.RegulationButton {
  font-family: "Project Bold";
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  color: var(--color-text-primary);
  background-color: transparent;
  border: none;
}

.auth-page-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.button-help {
  cursor: pointer;
  margin-top: 20px;
}
