.footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 40px 20px;
}

.footer_box {
    width: 115px;
    height: 85px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 5px;
    color: var(--color-text-primary);
}

.footer_box_instagram {
    width: 160px;
    height: 33px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 15px;
}

.footer_logo {
    width: 100px;
    cursor: pointer;
}

.footer_container p {
    font-size: 12px;
    color: var(--color-footer-primary);
    line-height: 14px;
}
