

.main_title_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;

    height: 70px;
}


.main_title_text {
    font-family: "Project Bold";
    color: var(--color-text-primary);
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    width: 210px;
}

.main_subtitle_text {
    text-align: center;
    color: var(--color-text-primary);
    margin-top: -0.2rem;
    font-size: 20px;
}
