
.author_container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  opacity: 0;
}

@media (min-height: 600px) {
  .author_container {
    margin-top: 2rem;
    opacity: 0;
  } 
}

.author_image {
}

.author_image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.author_title {
  margin-left: 0.5em;
  color: var(--color-text-primary);
  font-size: 35px !important;
  font-weight: bold;
  text-align: left;
  position: absolute;
}

.author_text {
  padding: 32px 64px 32px 64px;
  color: var(--color-text-primary);
  font-size: 18px !important;
  text-align: justify;
  hyphens: auto;
  line-height: 1.2;
}


@media  (min-width:600px) {
  .author_title {
    font-size: 40px;
    font-size: 50px;

    margin-left: 6rem;
  }

  .author_text {
    padding: 64px 92px;
    font-size: 20px;
  }

  .author_image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

