.info_small_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.info_small_buttons_container p {
    color: var(--color-text-primary);
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}
