.info_buttons_container {
  padding: 1em 0 1em 0;
}

.modal * {
  color: #565656;
}

.info_buttons_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_buttons_container button {
  font-family: "Project Bold";
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 12px;
  padding-bottom: 8px;
  width: 200px;
  border-width: 0px;
}

.card {
  height: 80%;
}
