


.preloader_animate {
    width: 100%;
    height:100%;
    background-image: url('./../images/p-loader.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;
}